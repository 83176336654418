<template>
  <div class="flex justify-center items-center px-6">
    <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
      <div class="flex justify-center items-center">
        <span class="text-gray-700 font-semibold text-2xl"
          >Supersonic Menu Login</span
        >
      </div>

      <form class="mt-4" @submit.prevent>
        <label class="block">
          <span class="text-gray-700 text-sm">Email</span>
          <input
            type="text"
            class="form-input mt-1 block w-full rounded-md focus:border-indigo-600"
            v-model.lazy="email"
          />
        </label>

        <label class="block mt-3">
          <span class="text-gray-700 text-sm">Password</span>
          <input
            type="password"
            class="form-input mt-1 block w-full rounded-md focus:border-indigo-600"
            v-model.lazy="password"
          />
        </label>

        <div class="mt-6">
          <button
            type="submit"
            class="py-2 px-4 text-center bg-indigo-600 rounded-md w-full text-white text-sm hover:bg-indigo-500"
            @click="runLogin"
          >
            Log in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useLogin } from "./hook";

export default {
  name: "Login",
  setup() {
    const { email, password, runLogin } = useLogin();

    return {
      email,
      password,
      runLogin,
    };
  },
};
</script>