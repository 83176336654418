import { firebaseDb } from "@/config/firebase";

export const getUser = async ({ uid = null } = {}) => {
    try {

        const snapshot = await firebaseDb
            .collection("users")
            .where("uid", "==", uid)
            .limit(1)
            .get();

        if (snapshot.docs.length) {
            const doc = snapshot.docs[0];
            return Promise.resolve({
                uid,
                ...(doc && doc.data())
            });
        }

    } catch (err) {
        const error = `Error: ${err.message}`;
        return Promise.reject(error);
    }
};