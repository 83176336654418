import { ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { login, getUser, getFullUser } from "@/services/auth";

export const useLogin = () => {

    const email = ref('');
    const password = ref('');

    const router = useRouter();
    const store = useStore();

    const runLogin = async () => {
        store.dispatch("setLoading", true);
        try {
            const { uid } = await login({ email: email.value, password: password.value });
            const user = await getUser({ uid });
            const fullUser = getFullUser({ user });
            store.dispatch('auth', fullUser);
            router.push('/');
        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    };

    return {
        email,
        password,
        runLogin,
    }
}