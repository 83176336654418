import { firebaseAuth } from "@/config/firebase";

export const login = async ({ email = null, password = null } = {}) => {
    try {
        const userAuth = await firebaseAuth.signInWithEmailAndPassword(
            email,
            password
        );
        const { uid, displayName } = userAuth.user;

        return Promise.resolve({ uid, email, displayName });

    } catch (err) {
        const error = `Error: ${err.message}`;
        return Promise.reject(error);
    }
};