
export const getFullUser = ({ user = {} } = {}) => {

    if (user.privilege === 'admin') {
        return {
            ...user,
            orgs: ['rb-nz-kfc', 'rb-nz-tb']
        }
    } else if (user.privilege === 'client') {
        switch (user.organisation) {
            case 'RB NZ KFC':
                return {
                    ...user,
                    orgs: ['rb-nz-kfc']
                }
            default:
                return {
                    ...user,
                    orgs: []
                }
        }
    }
};