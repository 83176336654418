import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const firebaseApp = firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DBURL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGID,
    appId: process.env.VUE_APP_FIREBASE_APPID
});

export const firebaseDb = firebaseApp.firestore();
export const firebaseAuth = firebaseApp.auth();